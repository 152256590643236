body {
    font-family: calibri, sans-serif;
}

.clear-float {
    clear:both;
}

.highlight {
    color:#876243;
}
.sans {
    font-family: calibri, sans-serif;
}

img.display-left {
    float:left;
    max-width: 50%;
    margin-right:10px;
    margin-bottom:10px;
}

img.display-right {
    float:right;
    max-width: 50%;
    margin-left:10px;
    margin-bottom:10px;
}

h1,h2,h3 {
    font-family: TimesNewRoman, "Times New Roman", Times, Baskerville, Georgia, serif;
    font-weight: normal;
}

.button,
.button-outline {
    background:#FFFFFF;
    color:#10100F;
    padding:10px;
    text-transform: uppercase;
    text-decoration:none;
    border: 1px solid #212121;
    display: inline-block;
    font-weight: bold;
}
.button-outline {
    border:2px solid #060606;
}

.two-col {
    width: 49%;
    display: inline-block;
    vertical-align: top;
    margin-bottom: 50px;
    color: #606060;
}
.two-col h3 {
    font-family: 'calibri', sans-serif;
}
h1 {
    font-size: 2em;}
h2 {
    font-size: 1.5em;
}
h3 {
    font-size:1.17em;
}
